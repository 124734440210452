export const direction = [
  {'value': 'N', 'display': 'N'},
  {'value': 'S', 'display': 'S'},
  {'value': 'W', 'display': 'W'},
  {'value': 'E', 'display': 'E'},
  {'value': 'BOTHDIR', 'display': 'BOTHDIR'},
];


export const limits = [
  5, 10, 15, 20, 50, 100
];


export const county = [
  {'value': 'Albany', 'display': 'Albany'},
  {'value': 'Bronx', 'display': 'Bronx'},
  {'value': 'Broome', 'display': 'Broome'},
  {'value': 'Chautauqua', 'display': 'Chautauqua'},
  {'value': 'Columbia', 'display': 'Columbia'},
  {'value': 'Delaware', 'display': 'Delaware'},
  {'value': 'Dutchess', 'display': 'Dutchess'},
  {'value': 'Erie', 'display': 'Erie'},
  {'value': 'Essex', 'display': 'Essex'},
  {'value': 'Fulton', 'display': 'Fulton'},
  {'value': 'Genesee', 'display': 'Genesee'},
  {'value': 'Greene', 'display': 'Greene'},
  {'value': 'Herkimer', 'display': 'Herkimer'},
  {'value': 'Kings', 'display': 'Kings'},
  {'value': 'Livingston', 'display': 'Livingston'},
  {'value': 'Madison', 'display': 'Madison'},
  {'value': 'Monroe', 'display': 'Monroe'},
  {'value': 'Montgomery', 'display': 'Montgomery'},
  {'value': 'N/A', 'display': 'N/A'},
  {'value': 'Nassau', 'display': 'Nassau'},
  {'value': 'New York', 'display': 'New York'},
  {'value': 'Oneida', 'display': 'Oneida'},
  {'value': 'Onondaga', 'display': 'Onondaga'},
  {'value': 'Ontario', 'display': 'Ontario'},
  {'value': 'Orange', 'display': 'Orange'},
  {'value': 'Otsego', 'display': 'Otsego'},
  {'value': 'Putnam', 'display': 'Putnam'},
  {'value': 'Queens', 'display': 'Queens'},
  {'value': 'Rensselaer', 'display': 'Rensselaer'},
  {'value': 'Richmond', 'display': 'Richmond'},
  {'value': 'Rockland', 'display': 'Rockland'},
  {'value': 'Saratoga', 'display': 'Saratoga'},
  {'value': 'Schenectady', 'display': 'Schenectady'},
  {'value': 'Schuyler', 'display': 'Schuyler'},
  {'value': 'Seneca', 'display': 'Seneca'},
  {'value': 'Steuben', 'display': 'Steuben'},
  {'value': 'Suffolk', 'display': 'Suffolk'},
  {'value': 'Ulster', 'display': 'Ulster'},
  {'value': 'Warren', 'display': 'Warren'},
  {'value': 'Westchester', 'display': 'Westchester'},
];


export const roadway = [
  {'value': '12th Street', 'display': '12th Street'},
  {'value': '3RD AVE', 'display': '3RD AVE'},
  {'value': 'ADIRONDACK NORTHWAY', 'display': 'ADIRONDACK NORTHWAY'},
  {'value': 'APM Terminal', 'display': 'APM Terminal'},
  {'value': 'Adirondack Northway Extension', 'display': 'Adirondack Northway Extension'},
  {'value': 'Atlantic City Expressway', 'display': 'Atlantic City Expressway'},
  {'value': 'Bear Mountain Bridge', 'display': 'Bear Mountain Bridge'},
  {'value': 'Bear Mountain State Parkway', 'display': 'Bear Mountain State Parkway'},
  {'value': 'Belt System - Cross Island Parkway', 'display': 'Belt System - Cross Island Parkway'},
  {'value': 'Belt System - Shore Parkway', 'display': 'Belt System - Shore Parkway'},
  {'value': 'Belt System - Southern Parkway', 'display': 'Belt System - Southern Parkway'},
  {'value': 'Bronx River Parkway', 'display': 'Bronx River Parkway'},
  {'value': 'Bronx-Whitestone Bridge', 'display': 'Bronx-Whitestone Bridge'},
  {'value': 'Brooklyn Bridge', 'display': 'Brooklyn Bridge'},
  {'value': 'CR-156', 'display': 'CR-156'},
  {'value': 'CT 15', 'display': 'CT 15'},
  {'value': 'CT 2', 'display': 'CT 2'},
  {'value': 'CT 72', 'display': 'CT 72'},
  {'value': 'CT 8', 'display': 'CT 8'},
  {'value': 'CT 9', 'display': 'CT 9'},
  {'value': 'CT 9N_X29_I91', 'display': 'CT 9N_X29_I91'},
  {'value': 'Commodore Barry Bridge', 'display': 'Commodore Barry Bridge'},
  {'value': 'Cross Bay Bridge', 'display': 'Cross Bay Bridge'},
  {'value': 'Cross County Parkway', 'display': 'Cross County Parkway'},
  {'value': 'FDR Drive', 'display': 'FDR Drive'},
  {'value': 'FREEMANS BRIDGE RD', 'display': 'FREEMANS BRIDGE RD'},
  {'value': 'Franklin D. Roosevelt Drive', 'display': 'Franklin D. Roosevelt Drive'},
  {'value': 'Garden State Parkway', 'display': 'Garden State Parkway'},
  {'value': 'George Washington Bridge', 'display': 'George Washington Bridge'},
  {'value': 'Goethals Bridge', 'display': 'Goethals Bridge'},
  {'value': 'Grand Central Parkway', 'display': 'Grand Central Parkway'},
  {'value': 'Harlem River Drive', 'display': 'Harlem River Drive'},
  {'value': 'Henry Hudson Bridge', 'display': 'Henry Hudson Bridge'},
  {'value': 'Holland Tunnel', 'display': 'Holland Tunnel'},
  {'value': 'Hutchinson River Parkway [NYC]', 'display': 'Hutchinson River Parkway [NYC]'},
  {'value': 'Hutchinson River Parkway [Westchester]', 'display': 'Hutchinson River Parkway [Westchester]'},
  {'value': 'I-190 - Niagara Thruway', 'display': 'I-190 - Niagara Thruway'},
  {'value': 'I-195', 'display': 'I-195'},
  {'value': 'I-278', 'display': 'I-278'},
  {'value': 'I-280', 'display': 'I-280'},
  {'value': 'I-287', 'display': 'I-287'},
  {'value': 'I-287 - Cross Westchester Expwy', 'display': 'I-287 - Cross Westchester Expwy'},
  {'value': 'I-295', 'display': 'I-295'},
  {'value': 'I-390', 'display': 'I-390'},
  {'value': 'I-481', 'display': 'I-481'},
  {'value': 'I-490', 'display': 'I-490'},
  {'value': 'I-495', 'display': 'I-495'},
  {'value': 'I-590', 'display': 'I-590'},
  {'value': 'I-676', 'display': 'I-676'},
  {'value': 'I-678', 'display': 'I-678'},
  {'value': 'I-684', 'display': 'I-684'},
  {'value': 'I-690', 'display': 'I-690'},
  {'value': 'I-691', 'display': 'I-691'},
  {'value': 'I-76', 'display': 'I-76'},
  {'value': 'I-78', 'display': 'I-78'},
  {'value': 'I-787', 'display': 'I-787'},
  {'value': 'I-80', 'display': 'I-80'},
  {'value': 'I-81', 'display': 'I-81'},
  {'value': 'I-84', 'display': 'I-84'},
  {'value': 'I-84W_X10_OFF', 'display': 'I-84W_X10_OFF'},
  {'value': 'I-84W_X8_O', 'display': 'I-84W_X8_O'},
  {'value': 'I-86', 'display': 'I-86'},
  {'value': 'I-87 - NYS Thruway', 'display': 'I-87 - NYS Thruway'},
  {'value': 'I-87 Major Deegan Expressway', 'display': 'I-87 Major Deegan Expressway'},
  {'value': 'I-87 Northway', 'display': 'I-87 Northway'},
  {'value': 'I-88', 'display': 'I-88'},
  {'value': 'I-90', 'display': 'I-90'},
  {'value': 'I-90 - Berkshire Connector', 'display': 'I-90 - Berkshire Connector'},
  {'value': 'I-90 - NYS Thruway', 'display': 'I-90 - NYS Thruway'},
  {'value': 'I-91', 'display': 'I-91'},
  {'value': 'I-91S_X_I95', 'display': 'I-91S_X_I95'},
  {'value': 'I-95', 'display': 'I-95'},
  {'value': 'I-95 - New England Thruway', 'display': 'I-95 - New England Thruway'},
  {'value': 'Jackie Robinson Parkway', 'display': 'Jackie Robinson Parkway'},
  {'value': 'Kingston-Rhinecliff Bridge', 'display': 'Kingston-Rhinecliff Bridge'},
  {'value': 'Korean Veterans Memorial Parkway', 'display': 'Korean Veterans Memorial Parkway'},
  {'value': 'Lincoln Tunnel', 'display': 'Lincoln Tunnel'},
  {'value': 'Marin Blvd', 'display': 'Marin Blvd'},
  {'value': 'Marine Parkway Bridge', 'display': 'Marine Parkway Bridge'},
  {'value': 'McLester Street', 'display': 'McLester Street'},
  {'value': 'Meadowbrook State Parkway', 'display': 'Meadowbrook State Parkway'},
  {'value': 'NJ 10', 'display': 'NJ 10'},
  {'value': 'NJ 139 Lower', 'display': 'NJ 139 Lower'},
  {'value': 'NJ 168', 'display': 'NJ 168'},
  {'value': 'NJ 17', 'display': 'NJ 17'},
  {'value': 'NJ 18', 'display': 'NJ 18'},
  {'value': 'NJ 182', 'display': 'NJ 182'},
  {'value': 'NJ 20', 'display': 'NJ 20'},
  {'value': 'NJ 208', 'display': 'NJ 208'},
  {'value': 'NJ 21', 'display': 'NJ 21'},
  {'value': 'NJ 24', 'display': 'NJ 24'},
  {'value': 'NJ 27', 'display': 'NJ 27'},
  {'value': 'NJ 28', 'display': 'NJ 28'},
  {'value': 'NJ 29', 'display': 'NJ 29'},
  {'value': 'NJ 3', 'display': 'NJ 3'},
  {'value': 'NJ 34', 'display': 'NJ 34'},
  {'value': 'NJ 35', 'display': 'NJ 35'},
  {'value': 'NJ 35 Spur', 'display': 'NJ 35 Spur'},
  {'value': 'NJ 36', 'display': 'NJ 36'},
  {'value': 'NJ 37', 'display': 'NJ 37'},
  {'value': 'NJ 4', 'display': 'NJ 4'},
  {'value': 'NJ 41', 'display': 'NJ 41'},
  {'value': 'NJ 42', 'display': 'NJ 42'},
  {'value': 'NJ 440', 'display': 'NJ 440'},
  {'value': 'NJ 47', 'display': 'NJ 47'},
  {'value': 'NJ 495', 'display': 'NJ 495'},
  {'value': 'NJ 55', 'display': 'NJ 55'},
  {'value': 'NJ 57', 'display': 'NJ 57'},
  {'value': 'NJ 7', 'display': 'NJ 7'},
  {'value': 'NJ 70', 'display': 'NJ 70'},
  {'value': 'NJ 73', 'display': 'NJ 73'},
  {'value': 'NJ 88', 'display': 'NJ 88'},
  {'value': 'NY 100', 'display': 'NY 100'},
  {'value': 'NY 100B', 'display': 'NY 100B'},
  {'value': 'NY 100C', 'display': 'NY 100C'},
  {'value': 'NY 104', 'display': 'NY 104'},
  {'value': 'NY 116', 'display': 'NY 116'},
  {'value': 'NY 123', 'display': 'NY 123'},
  {'value': 'NY 128', 'display': 'NY 128'},
  {'value': 'NY 12D', 'display': 'NY 12D'},
  {'value': 'NY 133', 'display': 'NY 133'},
  {'value': 'NY 135', 'display': 'NY 135'},
  {'value': 'NY 139', 'display': 'NY 139'},
  {'value': 'NY 17 [Orange]', 'display': 'NY 17 [Orange]'},
  {'value': 'NY 18', 'display': 'NY 18'},
  {'value': 'NY 199', 'display': 'NY 199'},
  {'value': 'NY 201', 'display': 'NY 201'},
  {'value': 'NY 202', 'display': 'NY 202'},
  {'value': 'NY 203', 'display': 'NY 203'},
  {'value': 'NY 213', 'display': 'NY 213'},
  {'value': 'NY 216', 'display': 'NY 216'},
  {'value': 'NY 22', 'display': 'NY 22'},
  {'value': 'NY 23', 'display': 'NY 23'},
  {'value': 'NY 24', 'display': 'NY 24'},
  {'value': 'NY 25', 'display': 'NY 25'},
  {'value': 'NY 26', 'display': 'NY 26'},
  {'value': 'NY 27', 'display': 'NY 27'},
  {'value': 'NY 28', 'display': 'NY 28'},
  {'value': 'NY 29', 'display': 'NY 29'},
  {'value': 'NY 300', 'display': 'NY 300'},
  {'value': 'NY 302', 'display': 'NY 302'},
  {'value': 'NY 303', 'display': 'NY 303'},
  {'value': 'NY 308', 'display': 'NY 308'},
  {'value': 'NY 30A', 'display': 'NY 30A'},
  {'value': 'NY 312', 'display': 'NY 312'},
  {'value': 'NY 32', 'display': 'NY 32'},
  {'value': 'NY 35', 'display': 'NY 35'},
  {'value': 'NY 365', 'display': 'NY 365'},
  {'value': 'NY 383', 'display': 'NY 383'},
  {'value': 'NY 390', 'display': 'NY 390'},
  {'value': 'NY 414', 'display': 'NY 414'},
  {'value': 'NY 43', 'display': 'NY 43'},
  {'value': 'NY 46', 'display': 'NY 46'},
  {'value': 'NY 5', 'display': 'NY 5'},
  {'value': 'NY 52', 'display': 'NY 52'},
  {'value': 'NY 55', 'display': 'NY 55'},
  {'value': 'NY 590', 'display': 'NY 590'},
  {'value': 'NY 66', 'display': 'NY 66'},
  {'value': 'NY 69', 'display': 'NY 69'},
  {'value': 'NY 7', 'display': 'NY 7'},
  {'value': 'NY 8', 'display': 'NY 8'},
  {'value': 'NY 82', 'display': 'NY 82'},
  {'value': 'NY 96', 'display': 'NY 96'},
  {'value': 'NY 97', 'display': 'NY 97'},
  {'value': 'NY 9A', 'display': 'NY 9A'},
  {'value': 'NY 9D', 'display': 'NY 9D'},
  {'value': 'NY 9G', 'display': 'NY 9G'},
  {'value': 'NY 9H', 'display': 'NY 9H'},
  {'value': 'NY 9N', 'display': 'NY 9N'},
  {'value': 'NY 9R', 'display': 'NY 9R'},
  {'value': 'New Hyde Park Road', 'display': 'New Hyde Park Road'},
  {'value': 'New Jersey Turnpike', 'display': 'New Jersey Turnpike'},
  {'value': 'New Jersey Turnpike - Eastern Spur', 'display': 'New Jersey Turnpike - Eastern Spur'},
  {'value': 'New Jersey Turnpike - Hudson County Extension', 'display': 'New Jersey Turnpike - Hudson County Extension'},
  {'value': 'New Jersey Turnpike - Pennsylvania Ext.', 'display': 'New Jersey Turnpike - Pennsylvania Ext.'},
  {'value': 'New Jersey Turnpike - Western Spur', 'display': 'New Jersey Turnpike - Western Spur'},
  {'value': 'New Jersey Turnpike/I-95', 'display': 'New Jersey Turnpike/I-95'},
  {'value': 'Newburgh Beacon Bridge', 'display': 'Newburgh Beacon Bridge'},
  {'value': 'North Avenue', 'display': 'North Avenue'},
  {'value': 'Northern State Parkway', 'display': 'Northern State Parkway'},
  {'value': 'Outerbridge Crossing', 'display': 'Outerbridge Crossing'},
  {'value': 'Palisades Interstate Parkway', 'display': 'Palisades Interstate Parkway'},
  {'value': 'Port Street', 'display': 'Port Street'},
  {'value': 'Pulaski Skyway', 'display': 'Pulaski Skyway'},
  {'value': 'Queens Midtown Tunnel', 'display': 'Queens Midtown Tunnel'},
  {'value': 'Queensboro Bridge', 'display': 'Queensboro Bridge'},
  {'value': 'RFK Triborough Bridge', 'display': 'RFK Triborough Bridge'},
  {'value': 'Ramp I-190 N To Peace Bridge', 'display': 'Ramp I-190 N To Peace Bridge'},
  {'value': 'Ramp I-190 S To I-90 W', 'display': 'Ramp I-190 S To I-90 W'},
  {'value': 'Ramp I-90 E To I-290 W', 'display': 'Ramp I-90 E To I-290 W'},
  {'value': 'Rip Van Winkle Bridge', 'display': 'Rip Van Winkle Bridge'},
  {'value': 'Rochester Inner Loop', 'display': 'Rochester Inner Loop'},
  {'value': 'Route 219 South', 'display': 'Route 219 South'},
  {'value': 'Route 33 West', 'display': 'Route 33 West'},
  {'value': 'Route 400 North', 'display': 'Route 400 North'},
  {'value': 'Sagtikos State Parkway', 'display': 'Sagtikos State Parkway'},
  {'value': 'Saw Mill River Parkway', 'display': 'Saw Mill River Parkway'},
  {'value': 'Southern State Parkway', 'display': 'Southern State Parkway'},
  {'value': 'Sprain Brook State Pkwy', 'display': 'Sprain Brook State Pkwy'},
  {'value': 'Taconic State Parkway', 'display': 'Taconic State Parkway'},
  {'value': 'Throgs Neck Bridge', 'display': 'Throgs Neck Bridge'},
  {'value': 'US 1', 'display': 'US 1'},
  {'value': 'US 1&9', 'display': 'US 1&9'},
  {'value': 'US 1&9 Truck Route', 'display': 'US 1&9 Truck Route'},
  {'value': 'US 1&9/Tonnelle Avenue', 'display': 'US 1&9/Tonnelle Avenue'},
  {'value': 'US 130', 'display': 'US 130'},
  {'value': 'US 202', 'display': 'US 202'},
  {'value': 'US 206', 'display': 'US 206'},
  {'value': 'US 22', 'display': 'US 22'},
  {'value': 'US 30', 'display': 'US 30'},
  {'value': 'US 40', 'display': 'US 40'},
  {'value': 'US 44', 'display': 'US 44'},
  {'value': 'US 46', 'display': 'US 46'},
  {'value': 'US 6', 'display': 'US 6'},
  {'value': 'US 9', 'display': 'US 9'},
  {'value': 'US 9W', 'display': 'US 9W'},
  {'value': 'Verrazano-Narrows Bridge', 'display': 'Verrazano-Narrows Bridge'},
  {'value': 'Wantagh State Parkway', 'display': 'Wantagh State Parkway'},
  {'value': 'Williamsburg Bridge', 'display': 'Williamsburg Bridge'},
];
